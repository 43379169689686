import React, { useCallback } from "react"
import PropTypes from "prop-types"
import styled, { useTheme } from "styled-components"
import Grid from "@components/elements/grid"
import List from "@components/elements/list"
import Div from "@components/elements/div"
import Button from "@components/elements/button"
import theme from "@styles/theme"
import { useCta } from "@hooks/use-cta"
import { PRODUCTS } from "@hooks/constants"
import { Tooltip } from "@components/common/tooltip"
import Span from "@components/elements/span"
import Flex from "@components/elements/flex"
import useDebouncedState from "@hooks/use-debounced-state"

const StyledList = styled(List)`
  margin-bottom: 0;
  li {
    color: ${props =>
      props.klips ? theme.color.blue500 : theme.color.indigo400};
    display: flex;
    align-items: center;
    height: 70px;
    line-height: 1.25;
    border-bottom: 0 !important;
    &:nth-child(odd) {
      background: #f7f9fc;
    }
  }
`

const ContactButton = ({ klips, children, ...rest }) => {
  const { color } = useTheme()

  return (
    <Div margin="auto 0 0">
      <Div margin="2rem 0" padding="0.75rem 2rem">
        <Button
          background="none"
          arrow
          color={klips ? color.blue600 : color.indigo400}
          notice={false}
          padding="0.75rem 0"
          style={{
            textAlign: "center",
            width: "100%",
            border: `2px solid ${klips ? color.blue600 : color.indigo400}`,
            lineHeight: 1,
          }}
          data-cta-location="pricing-table"
          {...rest}
        >
          {children}
        </Button>
      </Div>
    </Div>
  )
}

ContactButton.propTypes = {
  klips: PropTypes.bool,
  children: PropTypes.node,
}

const EntryTitle = ({ entry, monthly, title }) => {
  let value = ""
  if (entry?.monthly && entry?.annual) {
    value = monthly ? entry.monthly : entry.annual
  } else {
    value = entry.value
  }

  return title ? (
    <Flex gap="0.5rem">
      {title}
      <br />
      <Span color="inherit" fontWeight={500}>
        {value}
      </Span>
    </Flex>
  ) : (
    value
  )
}

EntryTitle.propTypes = {
  entry: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  monthly: PropTypes.bool,
  title: PropTypes.string,
}

const PricingItem = ({ index, entry, info, monthly, titles, klips }) => {
  const [isHovering, setIsHovering] = useDebouncedState(false, 200)
  const { color } = useTheme()

  const handleMouseOver = useCallback(() => {
    setIsHovering(true)
  }, [setIsHovering])

  const handleMouseOut = useCallback(() => {
    setIsHovering(false)
  }, [setIsHovering])

  const title = titles?.[index].value
  const hover = titles?.[index].hover || entry?.hover

  return (
    !(entry.hideMobile && titles) && (
      <li
        style={{
          color: info && color.indigo600,
          fontWeight: info ? "700" : "600",
          borderRight: info && "1px solid #ecf1f8",
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
        }}
        onMouseEnter={hover && handleMouseOver}
        onMouseLeave={hover && handleMouseOut}
      >
        <span style={{ paddingRight: "1rem" }}>
          <EntryTitle
            index={index}
            entry={entry}
            monthly={monthly}
            title={title}
          />
        </span>
        {hover && (
          <Tooltip klips={klips} index={index} isHovering={isHovering}>
            {hover}
          </Tooltip>
        )}
      </li>
    )
  )
}

PricingItem.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entry: PropTypes.object,
  info: PropTypes.bool,
  monthly: PropTypes.bool,
  titles: PropTypes.array,
  klips: PropTypes.bool,
}

export const PricingColumn = ({
  klips,
  button,
  list,
  info,
  monthly,
  handleYearlyClick,
  titles,
  isAvailableMonthly = true,
  onClick
}) => {
  const { ctaLink } = useCta(
    true,
    klips ? PRODUCTS.KLIPS : PRODUCTS.POWERMETRICS,
    false,
    true
  )

  return (
    <Grid
      rows="auto 1fr"
      transition="box-shadow .33s ease"
      background="white"
      position="relative"
      style={{ filter: !isAvailableMonthly && monthly ? "blur(2px)" : "" }}
    >
      {!isAvailableMonthly && (
        <Div
          position="absolute"
          zIndex={"2"}
          background={monthly ? "rgba(0,0,0,0.1)" : null}
          pointerEvents={!monthly ? "none" : null}
          transition="background 0.2s ease-in-out"
          left={0}
          right={0}
          bottom={0}
          top={0}
          onClick={handleYearlyClick}
          cursor="pointer"
        />
      )}
      <StyledList
        fontSize="1rem"
        gap="0"
        itemPadding="1rem 1.25rem"
        borderBottom="1px solid #ddd"
        margin="0 0 2rem"
        listStyle="none"
        klips={klips}
      >
        {list.map((entry, i) => {
          return (
            <PricingItem
              key={`pricing-item-${i}`}
              index={i}
              entry={entry}
              info={info}
              monthly={monthly}
              titles={titles}
              klips={klips}
            />
          )
        })}
      </StyledList>
      {button && (
        <ContactButton klips={klips} href={ctaLink}>
          Get Started
        </ContactButton>
      )}
      {onClick && (
        <ContactButton klips={klips} onClick={onClick}>
          Contact Us
        </ContactButton>
      )}
    </Grid>
  )
}

PricingColumn.propTypes = {
  klips: PropTypes.bool,
  button: PropTypes.bool,
  list: PropTypes.array,
  info: PropTypes.bool,
  contact: PropTypes.bool,
  handleYearlyClick: PropTypes.func,
  monthly: PropTypes.bool,
  titles: PropTypes.array,
  isAvailableMonthly: PropTypes.bool,
  onClick: PropTypes.func
}
