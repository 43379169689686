import React, { useCallback, useEffect, useState } from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { processImages } from "@utils/process-images"
import { PricingIcons } from "@components/pages/pricing/pricing-icons"
import { KlipsPricing } from "@components/pages/pricing/klips"
import { AnimatePresence, motion } from "framer-motion"
import { ProductSelector } from "@components/pages/pricing/selector"
import Div from "@components/elements/div"
import { useLocation } from "@hooks/use-location"
import { PRODUCTS } from "@hooks/use-product"
import { PowerMetricsTable } from "@components/pages/pricing/powermetrics-table"
import { useGradient } from "@hooks/use-gradient"
import { HighGradient } from "@components/common/gradient"
import Flex from "@components/elements/flex"
import Container from "@components/layout/container"
import Heading from "@components/elements/heading"
import Button from "@components/elements/button"
import { useTheme } from "styled-components"
import { GetADemoForm } from "@components/forms/get-a-demo"
import { PowerMetricsEOPCta } from "@components/cta/eop/powermetrics"

// TODO: Swap to use two separate pages, use canonical tag to make them both /pricing
const PricingPage = ({ data }) => {
  const images = processImages(data.images.edges)
  const location = useLocation()
  const [type, setType] = useState()
  const [klips, setKlips] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const product = urlParams.get("product")
    setKlips(product === "klips")
    setType(urlParams.get("type"))
  }, [])

  const { gradient } = useGradient()
  const { color } = useTheme()

  const [modalOpen, setModalOpen] = useState(false)

  const handleClick = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  const [monthly, setMonthly] = useState(true)

  const handleMonthlyClick = useCallback(() => {
    setMonthly(true)
  }, [])
  const handleYearlyClick = useCallback(() => {
    setMonthly(false)
  }, [])

  return (
    <Layout
      title={`Business Dashboards and Reporting Software for Everyone`}
      description={`Klipfolio is the leading platform for self-service BI, dashboards, and reporting. From startups to Fortune 500 teams, Klipfolio provides the power and flexibility you always wanted at an affordable price. Succeed with data analytics today and start for FREE.`}
      fullWidth
      marginless
      productOverride={klips ? PRODUCTS.KLIPS : PRODUCTS.POWERMETRICS}
      hideOverflow
      login
    >
      <GetADemoForm modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <Div margin="9rem 0 0">
        <ProductSelector klips={klips} setKlips={setKlips} />
        <AnimatePresence initial={false} mode="wait">
          {klips ? (
            <motion.div
              key="klipsPricing"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <KlipsPricing
                images={images}
                type={type}
                monthly={monthly}
                handleMonthlyClick={handleMonthlyClick}
                handleYearlyClick={handleYearlyClick}
              />
            </motion.div>
          ) : (
            <motion.div
              key="pmPricing"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Div container margin="0 auto 0" position="relative">
                <HighGradient top="12rem" src={gradient.cdn} loading="eager" />
                <Flex center margin="0 auto 5rem" alignItems="center">
                  <Container center text>
                    <header>
                      <Heading
                        fontSize="2.8rem"
                        lineHeight="120%"
                        margin="0 0 2rem"
                      >
                        Analytics designed for business teams with pricing that
                        works for you.
                      </Heading>
                    </header>
                    <Button.PowerMetrics
                      data-cta-location="header"
                      force
                      noticeColor={color.indigo600}
                    />
                  </Container>
                </Flex>
              </Div>

              <PowerMetricsTable
                monthly={monthly}
                handleClick={handleClick}
                handleMonthlyClick={handleMonthlyClick}
                handleYearlyClick={handleYearlyClick}
              />

              <PowerMetricsEOPCta
                force
                heading={"Make analytics everybody's business."}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </Div>
      <PricingIcons />
    </Layout>
  )
}

PricingPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query PricingPageQuery {
    images: allDirectusFiles(
      filter: { directus_id: { in: ["01c7bbb0-277d-499b-be8a-46c14c00f95f"] } }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`

export default PricingPage
