import React from "react"
import PropTypes from "prop-types"
import styled, { css, useTheme } from "styled-components"
import Paragraph from "@components/elements/paragraph"
import Div from "@components/elements/div"
import Span from "@components/elements/span"
import Flex from "@components/elements/flex"
import { AnimatePresence } from "framer-motion"
import { motion } from "framer-motion"

const StyledSpan = styled(Span)`
  display: flex;
  ::after {
    color: ${({ color }) => color};
    font-weight: 600;
    font-size: 16px;
    line-height: 50px;
    margin-left: 3px;
  }
  ${({ monthly, isNumber }) =>
    monthly &&
    isNumber &&
    css`
      ::after {
        content: "/mo";
      }
    `}
  ${({ monthly, isNumber }) =>
    !monthly &&
    isNumber &&
    css`
      ::after {
        content: "/yr";
      }
    `}
`

const PricingInfo = ({ klips, condition, value }) => {
  const { color } = useTheme()
  return (
    <Div margin="auto -1.5rem -1.5rem">
      <Div
        margin={klips ? "0.5rem 0 0" : "1.5rem 0 0"}
        width={condition ? "100%" : "0"}
        padding={condition ? "0.25rem 0.75rem 0.25rem 1.5rem" : "0.25rem 0"}
        fontSize="0.7rem"
        background={klips ? color.blue400 : color.indigo400}
        overflow="hidden"
        transition="width 0.3s ease-in-out, padding 0.3s ease-in-out"
        whiteSpace="nowrap"
      >
        <Span color="white" whiteSpace="nowrap" fontWeight="700">
          {value}
        </Span>
      </Div>
    </Div>
  )
}

PricingInfo.propTypes = {
  klips: PropTypes.bool,
  condition: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
}

export const PricingHeading = ({
  heading,
  annualPrice,
  monthlyPrice,
  monthly,
  info,
  klips,
  description,
  isAvailableMonthly = true,
  handleYearlyClick,
  ...rest
}) => {
  const { color } = useTheme()
  const isNumber = typeof monthlyPrice === "number"

  return (
    <Flex
      minHeight="145px"
      padding={!info && "1.5rem"}
      style={{
        borderBottom: "1px solid #ecf1f8",
        borderRight: info && "1px solid #ecf1f8",
        justifyContent: info && "center",
      }}
      {...rest}
      position="relative"
    >
      {!isAvailableMonthly && (
        <Div
          position="absolute"
          zIndex={"1"}
          background={monthly ? "rgba(0,0,0,0.1)" : null}
          pointerEvents={!monthly ? "none" : null}
          transition="background 0.2s ease-in-out"
          top={0}
          left={0}
          right={0}
          bottom={"-1px"}
          borderRadius="0 1rem 0 0"
          onClick={handleYearlyClick}
          cursor="pointer"
        />
      )}
      {heading && (
        <Paragraph
          lineHeight="1"
          fontSize="1.5rem"
          color={klips ? color.blue400 : color.indigo400}
          fontWeight="700"
          margin="0 0 0.5rem"
        >
          {heading}
        </Paragraph>
      )}
      {!info && (monthlyPrice || annualPrice) && (
        <Flex flexFlow="row" alignItems="flex-start">
          <StyledSpan
            fontSize="2rem"
            fontWeight="700"
            color={klips ? color.blue600 : color.indigo600}
            isNumber={isNumber}
            monthly={monthly}
            lineHeight="1.3"
          >
            {isNumber ? (
              <>
                <Span color="inherit" area="dollar">
                  $
                </Span>
                <AnimatePresence initial={false} mode="popLayout">
                  {monthly ? (
                    <motion.div
                      key="monthlyPrice"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {new Intl.NumberFormat().format(monthlyPrice)}
                    </motion.div>
                  ) : (
                    <motion.div
                      key="annualPrice"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {new Intl.NumberFormat().format(annualPrice * 12)}
                    </motion.div>
                  )}
                </AnimatePresence>
              </>
            ) : (
              monthlyPrice
            )}
          </StyledSpan>
        </Flex>
      )}
      {description && (
        <Flex
          margin={monthlyPrice || annualPrice ? "0" : "0.5rem 0 0"}
          flexFlow="row"
          justifyContent="space-between"
        >
          <Paragraph maxWidth="43ch" fontSize="0.9rem" lineHeight={1.4}>
            {description}
          </Paragraph>
        </Flex>
      )}
      {isNumber && (
        <PricingInfo
          klips={klips}
          condition={!monthly}
          value={`SAVE $${monthlyPrice * 12 - annualPrice * 12}/yr on an annual
            plan`}
        />
      )}
      {!isAvailableMonthly && (
        <PricingInfo
          klips={klips}
          condition={monthly}
          value={`Annual plan only`}
        />
      )}
      {info}
    </Flex>
  )
}

PricingHeading.propTypes = {
  heading: PropTypes.string,
  monthlyPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  annualPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  monthly: PropTypes.bool,
  info: PropTypes.node,
  klips: PropTypes.bool,
  description: PropTypes.string,
  hover: PropTypes.string,
  isAvailableMonthly: PropTypes.bool,
  handleYearlyClick: PropTypes.func,
}
