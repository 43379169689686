import React from "react"
import { Accordion } from "@components/common/accordion"
import Paragraph from "@components/elements/paragraph"
import Anchor from "@components/elements/anchor"
import PropTypes from "prop-types"
import { useMicrosite } from "@hooks/use-microsite"

export const PowerMetricsPricingFAQ = ({ handleClick, servicesPage }) => {
  const microsite = useMicrosite()
  return (
    <>
      <Accordion title={"What is PowerMetrics?"}>
        <Paragraph fontSize="1.1rem">
          PowerMetrics is a self-serve metrics platform and complementary
          analytics solution. It enables data teams to manage organizational
          data while providing controlled access to business users with a
          metrics catalog and streamlined metric-centric analytics.
        </Paragraph>
        <Paragraph fontSize="1.1rem">
          As a hybrid metrics solution, PowerMetrics has the ability to query
          data directly from your data warehouse, import metrics from your
          semantic layer and create native metrics from common file formats and
          hundreds of data services. Explore our&nbsp;
          <Anchor
            link={microsite ? "/integrations" : "/powermetrics/integrations"}
          >
            hybrid integrations approach
          </Anchor>
          .
        </Paragraph>
      </Accordion>
      <Accordion
        title={"How does PowerMetrics protect privacy and ensure security?"}
      >
        <Paragraph fontSize="1.1rem">
          We care deeply about the availability, confidentiality, and integrity
          of your data. Your continued trust in us is our top priority. Read
          more about&nbsp;
          <Anchor
            target="_blank"
            rel="noreferrer"
            link="https://www.klipfolio.com/legal/security"
          >
            how we protect
          </Anchor>
          &nbsp;and ensure&nbsp;
          <Anchor
            target="_blank"
            rel="noreferrer"
            link="https://www.klipfolio.com/legal/privacy"
          >
            privacy
          </Anchor>
          &nbsp;of your data.
        </Paragraph>
      </Accordion>
      <Accordion title={"Is PowerMetrics a Klipfolio product?"}>
        <Paragraph fontSize="1.1rem">
          Yes. PowerMetrics is one of two products built and owned by&nbsp;
          <Anchor link="https://www.klipfolio.com/">Klipfolio</Anchor>.
          PowerMetrics is a complementary analytics solution with a
          metric-centric architecture that&apos;s designed for data teams and
          business users in larger organizations.&nbsp;
          <Anchor link="/klips">Klipfolio Klips</Anchor>
          &nbsp;is an award-winning dashboarding solution for small and
          medium-sized businesses.
        </Paragraph>
      </Accordion>
      <Accordion title={"Does PowerMetrics have a partner program?"}>
        <Paragraph fontSize="1.1rem">
          Yes. We collaborate closely with our partners, who, just like us, are
          on a mission to bring data analytics to everyone. Selected partners
          can also buy a partner license to help them grow their client
          base.&nbsp;<Anchor onClick={handleClick}>Contact Us</Anchor> to learn
          more.
        </Paragraph>
      </Accordion>
      <Accordion title={"Can I upgrade and downgrade any time?"}>
        <Paragraph fontSize="1.1rem">
          Absolutely. Our flexible payment plans allow you to upgrade,
          downgrade, or cancel at any time. Cancellations take effect at the end
          of your billing period.
        </Paragraph>
      </Accordion>
      <Accordion title={"Are there any setup or onboarding fees?"}>
        <Paragraph fontSize="1.1rem">
          There are no hidden setup or onboarding fees with any of our plans.
          However, if you want some help getting started, our Professional
          Services Team can provide onboarding and training to your entire team.
          While optional, we recommend this service to all new customers. Learn
          more about&nbsp;
          <Anchor link={servicesPage}>our services</Anchor>.
        </Paragraph>
      </Accordion>
      <Accordion title={"Which payment methods does PowerMetrics accept?"}>
        <Paragraph fontSize="1.1rem">
          You can pay for your subscription using Visa, Mastercard, American
          Express, or PayPal. We can also set up invoicing for larger payments;
          please&nbsp;
          <Anchor link="/about/contact-us">contact our Success Team</Anchor>.
        </Paragraph>
      </Accordion>
      <Accordion title={"Are prices in American currency?"}>
        <Paragraph fontSize="1.1rem">
          Yes, all prices are listed in USD.
        </Paragraph>
      </Accordion>
      <Accordion title={"How does PowerMetrics define a user?"}>
        <Paragraph fontSize="1.1rem">
          A user is a named individual who can log into PowerMetrics using a
          unique email address. Each user is assigned to one or more roles. Each
          role includes a unique set of permissions that allow and restrict
          access to metric assets and their management.
        </Paragraph>
      </Accordion>
      <Accordion title={"How do I add more users?"}>
        <Paragraph fontSize="1.1rem">
          You can add users, one at a time, using our&nbsp;
          <Anchor link="#add-ons">add-on options</Anchor>.
        </Paragraph>
      </Accordion>
      <Accordion
        title={
          "Does PowerMetrics offer a discount rate for Not-for-Profit organizations?"
        }
      >
        <Paragraph fontSize="1.1rem">
          Absolutely. We&apos;re proud of, and humbled by, the Not-for-Profit
          organizations around the world who choose PowerMetrics. Get in&nbsp;
          <Anchor link="/about/contact-us">touch with our team</Anchor>
          &nbsp;to learn more.
        </Paragraph>
      </Accordion>
    </>
  )
}

PowerMetricsPricingFAQ.propTypes = {
  handleClick: PropTypes.func.isRequired,
  servicesPage: PropTypes.string.isRequired,
}
