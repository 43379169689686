import React, { useState } from "react"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Container from "@components/layout/container"
import Flex from "@components/elements/flex"
import Anchor from "@components/elements/anchor"
import Div from "@components/elements/div"
import Image from "@components/image"
import List from "@components/elements/list"
import { KlipsSelector } from "@components/pages/pricing/selector"
import styled, { useTheme } from "styled-components"
import Button from "@components/elements/button"
import PropTypes from "prop-types"
import { KlipsAgencyPricing, KlipsBusinessPricing } from "./klips-pricing-table"
import { AnimatePresence } from "framer-motion"
import { KlipsFAQ } from "@components/faq/klips"
import { ServicesBlock } from "@components/common/services-block"
import { useGradient } from "@hooks/use-gradient"
import { HighGradient } from "@components/common/gradient"
import { KlipsEOPCta } from "@components/cta/eop/klips"

const LogoWrapper = styled.div`
  svg {
    max-width: 700px;
  }
`

const PRICING_TYPES = {
  AGENCY: "agency",
  BUSINESS: "business",
}

export const KlipsPricing = ({
  images,
  monthly,
  handleMonthlyClick,
  handleYearlyClick,
  type = PRICING_TYPES.BUSNESS,
}) => {
  const { color, shadow } = useTheme()
  const { gradient } = useGradient(true)
  const [agency, setAgency] = useState(type === PRICING_TYPES.AGENCY)

  return (
    <>
      <Div container position="relative">
        <HighGradient top="12rem" src={gradient.cdn} loading="lazy" />
        <Flex center margin="0 auto 5rem" alignItems="center">
          <Container center text>
            <header>
              <Heading
                color={color.blue600}
                fontSize="3rem"
                lineHeight="110%"
                margin="0 0 2rem 0"
              >
                Custom dashboards,
                <br />
                affordable pricing.
              </Heading>
            </header>
            <Button.Klips force arrow data-cta-location="header" />
          </Container>
        </Flex>
        <KlipsSelector
          agency={agency}
          setAgency={setAgency}
          style={{ margin: "0 auto 2rem", position: "relative" }}
        />
        <Div container position="relative">
          <AnimatePresence mode="wait">
            {agency ? (
              <KlipsAgencyPricing
                key="klipsAgencyTable"
                monthly={monthly}
                handleMonthlyClick={handleMonthlyClick}
                handleYearlyClick={handleYearlyClick}
              />
            ) : (
              <KlipsBusinessPricing
                key="klipsBusinessTable"
                monthly={monthly}
                handleMonthlyClick={handleMonthlyClick}
                handleYearlyClick={handleYearlyClick}
              />
            )}
          </AnimatePresence>
          <Paragraph fontSize="1rem" margin="0 0 4rem" center>
            Prices in US Dollars. No hidden onboarding fees.
          </Paragraph>
          <Flex textContainer gap="2rem">
            <Div
              boxShadow={shadow.soft}
              background="white"
              padding="1.5rem"
              borderRadius="1rem"
            >
              <Paragraph color={color.blue600}>
                Interested in a custom plan?&nbsp;
                <Anchor color={color.blue400} link="/contact-us">
                  Contact us
                </Anchor>
                . Our team is ready to help.
              </Paragraph>
            </Div>
            <Div
              boxShadow={shadow.soft}
              background="white"
              padding="1.5rem"
              borderRadius="1rem"
            >
              <Heading
                color={color.blue600}
                as="h3"
                fontSize="1.6rem"
                margin="0 0 1rem"
              >
                Top-Ups and Power-Ups
              </Heading>
              <Paragraph
                color={color.blue600}
                fontSize="1.1rem"
                margin="0 0 1rem"
              >
                Choose from an extensive list of Top-Ups that give you room to
                grow and Power-Ups that support you every step of the way, such
                as:
              </Paragraph>
              <List
                margin="0 0 1rem 2rem"
                color={color.blue600}
                fontSize="1.1rem"
                gap="0rem"
              >
                <li>Add dashboards: $8/mo</li>
                <li>Near Real-time Data Refresh: $139/mo</li>
                <li>SSO (Single Sign-On): $49/mo</li>
                <li>Large Data Processing: Contact Us</li>
              </List>
              <Anchor
                arrow
                fontSize="1.1rem"
                link="https://www.klipfolio.com/klips-client-reporting/power-ups"
                color={color.blue400}
              >
                Learn more
              </Anchor>
            </Div>
          </Flex>
          <ServicesBlock
            background={color.blue100}
            text={color.blue600}
            style={{ marginTop: "8rem" }}
          />
          <Div margin="12rem 0" center>
            <Heading color={color.blue600} as="h3" margin="0 0 2rem">
              Trusted by over 50,000 people across the world
            </Heading>
            <LogoWrapper>
              <Image
                style={{
                  filter:
                    "grayscale(100%) sepia(25%) saturate(2585%) hue-rotate(161deg) brightness(95%) contrast(103%)",
                }}
                objectFit="scale-down"
                file={images["01c7bbb0-277d-499b-be8a-46c14c00f95f"]}
              />
            </LogoWrapper>
          </Div>
          <KlipsFAQ />
        </Div>
      </Div>
      <KlipsEOPCta
        force
        heading={
          <span style={{ display: "block", maxWidth: "26ch" }}>
            Discover the power of Klips for you and your team
          </span>
        }
        description={
          "Try Klips for free for 14 days to uncover the features and discover what you can build."
        }
        padding="6rem 0"
      />
    </>
  )
}

KlipsPricing.propTypes = {
  images: PropTypes.object.isRequired,
  monthly: PropTypes.bool,
  handleMonthlyClick: PropTypes.func,
  handleYearlyClick: PropTypes.func,
  type: PropTypes.string,
}
