import Flex from "@components/elements/flex"
import Span from "@components/elements/span"
import React, { useCallback } from "react"
import { useTheme } from "styled-components"
import PropTypes from "prop-types"

import { ReactComponent as KlipsIconLogo } from "@images/comparison/klips-icon.svg"
import { ReactComponent as PowerMetricsIconLogo } from "@images/comparison/powermetrics-icon.svg"
import Div from "@components/elements/div"

export const ProductSelector = ({ klips, setKlips }) => {
  const { color, shadow } = useTheme()

  const handlePmClick = useCallback(() => {
    setKlips(false)
  }, [])

  const handleKlipsClick = useCallback(() => {
    setKlips(true)
  }, [])

  return (
    <Flex
      boxShadow={shadow.soft}
      borderRadius={"3rem"}
      background="white"
      width="fit-content"
      flexFlow="row"
      alignSelf="center"
      margin="0 auto 3rem"
      padding="0.5rem 0.75rem"
      center
      position="relative"
      zIndex={1}
    >
      <Div
        borderRadius="2rem"
        padding="0.5rem 1rem"
        background={!klips ? color.indigo100 : null}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        color={color.indigo400}
        onClick={handlePmClick}
      >
        <Flex
          flexFlow="row"
          gap="0.5rem"
          padding="0 1rem"
          alignItems="center"
          alignSelf="center"
        >
          <PowerMetricsIconLogo height="20px" />
          <Span color="inherit" fontWeight={!klips ? "700" : "600"}>
            PowerMetrics
          </Span>
        </Flex>
      </Div>
      <Div
        borderRadius="2rem"
        padding="0.5rem 1rem"
        background={klips ? color.blue100 : null}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        color={color.blue500}
        onClick={handleKlipsClick}
      >
        <Flex
          padding="0 1rem"
          flexFlow="row"
          gap="0.5rem"
          alignItems="center"
          alignSelf="center"
        >
          <KlipsIconLogo height="20px" />
          <Span color="inherit" fontWeight={klips ? "700" : "600"}>
            Klips
          </Span>
        </Flex>
      </Div>
    </Flex>
  )
}

ProductSelector.propTypes = {
  klips: PropTypes.bool.isRequired,
  setKlips: PropTypes.func.isRequired,
}

export const KlipsSelector = ({ agency, setAgency, ...rest }) => {
  const { color, shadow } = useTheme()

  const handleAgencyClick = useCallback(() => {
    setAgency(true)
  }, [])

  const handleBusinessClick = useCallback(() => {
    setAgency(false)
  }, [])

  return (
    <Flex
      boxShadow={shadow.soft}
      padding="0.5rem"
      borderRadius={"3rem"}
      background="white"
      width="max-content"
      flexFlow="row"
      alignSelf="center"
      {...rest}
    >
      <Div
        borderRadius="2rem"
        background={agency ? color.blue100 : null}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        color={color.indigo400}
        padding="0.25rem 1rem"
        onClick={handleAgencyClick}
      >
        <Span color={color.blue500} fontWeight={agency ? "700" : "600"}>
          For Agencies
        </Span>
      </Div>
      <Div
        borderRadius="2rem"
        background={!agency ? color.blue100 : null}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        color={color.blue500}
        padding="0.25rem 1rem"
        onClick={handleBusinessClick}
      >
        <Span color="inherit" fontWeight={!agency ? "700" : "600"}>
          For Businesses
        </Span>
      </Div>
    </Flex>
  )
}

KlipsSelector.propTypes = {
  agency: PropTypes.bool.isRequired,
  setAgency: PropTypes.func.isRequired,
  rest: PropTypes.object,
}

export const Selector = ({ klips, monthly, handleMonthlyClick, handleYearlyClick, ...rest }) => {
  const { color, shadow } = useTheme()

  const backgroundColor = klips ? color.blue100 : color.indigo100
  const textColor = klips ? color.blue500 : color.indigo400

  return (
    <Flex
      boxShadow={shadow.soft}
      padding="0.5rem"
      borderRadius={"3rem"}
      background="white"
      width="min-content"
      flexFlow="row"
      alignSelf="center"
      {...rest}
    >
      <Div
        borderRadius="2rem"
        background={monthly ? backgroundColor : null}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        padding="0.25rem 1rem"
        onClick={handleMonthlyClick}
      >
        <Span color={textColor} fontWeight={monthly ? "700" : "600"}>
          Monthly
        </Span>
      </Div>
      <Div
        borderRadius="2rem"
        background={!monthly ? backgroundColor : null}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        padding="0.25rem 1rem"
        onClick={handleYearlyClick}
      >
        <Span color={textColor} fontWeight={!monthly ? "700" : "600"}>
          Annual
        </Span>
      </Div>
    </Flex>
  )
}

Selector.propTypes = {
  klips: PropTypes.bool,
  monthly: PropTypes.bool.isRequired,
  handleMonthlyClick: PropTypes.func.isRequired,
  handleYearlyClick: PropTypes.func.isRequired,
  toggled: PropTypes.bool,
}
