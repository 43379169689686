import React from "react"
import { ReactComponent as CheckmarkIcon } from "@images/icons/checkmark-circle.svg"
import { ReactComponent as NoCheckmarkIcon } from "@images/icons/no-checkmark-circle.svg"

export const KLIPS_AGENCY_LITE_HEADER = {
  heading: "Agency Lite",
  description:
    "Free 14 day trial.",
  monthlyPrice: 190,
  annualPrice: 170,
  klips: true,
}

export const KLIPS_AGENCY_PRO_HEADER = {
  heading: "Agency Pro",
  monthlyPrice: 420,
  annualPrice: 380,
  klips: true,
}

export const KLIPS_AGENCY_PREMIER_HEADER = {
  heading: "Agency Premier",
  monthlyPrice: 1025,
  annualPrice: 900,
  klips: true,
}

export const KLIPS_AGENCY_COMPARISON = [
  { value: "Dashboards" },
  { value: "Clients" },
  {
    value: "Users",
  },
  {
    value: "Data Feeds Integrations",
  },
  {
    value: "Data Modelling + Join",
  },
  { value: "Data Refresh Rate" },
  { value: "Add Your Logo" },
  {
    value: "Published Links",
    hover:
      "Published links enable others to view your dashboards, either as a public URL (available via Search) or with access to a unique, non-indexable URL and, optionally, a password.",
  },
  { value: "PDF Reports" },
  {
    value: "Scheduled Reports",
    hover:
      "Email a snapshot of a dashboard to share with others as a one-time event or according to a schedule. Content can be shared as a PDF or as an image.",
  },
  { value: "Priority Support" },
  {
    value: "Branding+",
    hover:
      "Promote and maintain your brand by putting your company name front and centre. Change key terminology (like “Klip” and “Dashboard”) throughout the product. Put your logo on the header and footer of every page and  every report.",
  },
  { value: "Custom Domain" },
  { value: "Single Sign On (SSO)" },
  { value: "Warm Up Refresh", hover: "Set a daily time for all of your data sources to refresh." },
  { value: "Custom Theme" },
  {
    value: "Custom Onboarding",
    hover:
      "Get six hours of custom onboarding within your first three months, performed by our Professional Services team.",
  },
]

export const KLIPS_AGENCY_LITE = [
  { value: "20" },
  { value: "20" },
  { value: "Unlimited" },
  { value: "130+" },
  { value: <CheckmarkIcon /> },
  { value: "1hr" },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
]

export const KLIPS_AGENCY_PRO = [
  { value: "40" },
  { value: "40" },
  { value: "Unlimited" },
  { value: "130+" },
  { value: <CheckmarkIcon /> },
  { value: "30min" },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <NoCheckmarkIcon />, hideMobile: true },
  { value: <NoCheckmarkIcon />, hideMobile: true },
]

export const KLIPS_AGENCY_PREMIER = [
  { value: "70" },
  { value: "70" },
  { value: "Unlimited" },
  { value: "130+" },
  { value: <CheckmarkIcon /> },
  { value: "30min" },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
  { value: <CheckmarkIcon /> },
]
