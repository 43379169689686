import Div from "@components/elements/div"
import Grid from "@components/elements/grid"
import React from "react"
import { PricingHeading } from "./pricing-header"
import { PricingColumn } from "./pricing-column"
import styled, { useTheme } from "styled-components"
import media from "../../../styles/media"
import { motion } from "framer-motion"
import {
  KLIPS_BUSINESS_COMPARISON,
  KLIPS_BUSINESS_GROW,
  KLIPS_BUSINESS_GROW_HEADER,
  KLIPS_BUSINESS_TEAM,
  KLIPS_BUSINESS_TEAMPLUS,
  KLIPS_BUSINESS_TEAMPLUS_HEADER,
  KLIPS_BUSINESS_TEAM_HEADER,
} from "./klips/business-pricing-constants"
import { Selector } from "./selector"
import {
  KLIPS_AGENCY_COMPARISON,
  KLIPS_AGENCY_LITE,
  KLIPS_AGENCY_LITE_HEADER,
  KLIPS_AGENCY_PREMIER,
  KLIPS_AGENCY_PREMIER_HEADER,
  KLIPS_AGENCY_PRO,
  KLIPS_AGENCY_PRO_HEADER,
} from "./klips/agency-pricing-constants"
import PropTypes from "prop-types"

const MobileWrapper = styled(Div)`
  ${media.md_up`
    display: none;
  `}
`

export const KlipsAgencyPricing = ({
  monthly,
  handleMonthlyClick,
  handleYearlyClick,
}) => {
  const { shadow } = useTheme()
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <Div className="pricing-main" hideOnMd>
        <Grid
          boxShadow={"0px 4px 32px rgb(0 0 0 / 5%)"}
          columns="repeat(4, 1fr)"
          background="white"
          columnsMd="1fr"
          borderRadius="1rem 1rem 0 0"
          position="sticky"
          top="6rem"
          zIndex={2}
        >
          <PricingHeading
            klips
            info={
              <Selector
                klips
                monthly={monthly}
                handleMonthlyClick={handleMonthlyClick}
                handleYearlyClick={handleYearlyClick}
              />
            }
          />
          <PricingHeading {...KLIPS_AGENCY_LITE_HEADER} monthly={monthly} />
          <PricingHeading {...KLIPS_AGENCY_PRO_HEADER} monthly={monthly} />
          <PricingHeading {...KLIPS_AGENCY_PREMIER_HEADER} monthly={monthly} />
        </Grid>
        <Grid
          boxShadow={shadow.soft}
          columns="repeat(4, 1fr)"
          background="white"
          columnsMd="1fr"
          borderRadius="0 0 1rem 1rem"
          overflow="hidden"
          margin="0 0 2rem"
        >
          <PricingColumn klips info list={KLIPS_AGENCY_COMPARISON} />
          <PricingColumn klips button list={KLIPS_AGENCY_LITE} />
          <PricingColumn klips button list={KLIPS_AGENCY_PRO} />
          <PricingColumn klips button list={KLIPS_AGENCY_PREMIER} />
        </Grid>
      </Div>
      <MobileWrapper className="pricing-mobile">
        <Selector
          klips
          monthly={monthly}
          handleMonthlyClick={handleMonthlyClick}
          handleYearlyClick={handleYearlyClick}
          style={{ margin: "0 auto 2rem", zIndex: 1, position: "relative" }}
        />
        <Div
          borderRadius="1rem"
          overflow="hidden"
          boxShadow={shadow.soft}
          margin="0 0 2rem"
          background="white"
        >
          <PricingHeading {...KLIPS_AGENCY_LITE_HEADER} monthly={monthly} />
          <PricingColumn
            klips
            button
            list={KLIPS_AGENCY_LITE}
            titles={KLIPS_AGENCY_COMPARISON}
            monthly={monthly}
          />
        </Div>
        <Div
          borderRadius="1rem"
          overflow="hidden"
          boxShadow={shadow.soft}
          margin="0 0 2rem"
          background="white"
        >
          <PricingHeading {...KLIPS_AGENCY_PRO_HEADER} monthly={monthly} />
          <PricingColumn
            klips
            button
            list={KLIPS_AGENCY_PRO}
            titles={KLIPS_AGENCY_COMPARISON}
            monthly={monthly}
          />
        </Div>
        <Div
          borderRadius="1rem"
          overflow="hidden"
          boxShadow={shadow.soft}
          margin="0 0 2rem"
          background="white"
        >
          <PricingHeading {...KLIPS_AGENCY_PREMIER_HEADER} monthly={monthly} />
          <PricingColumn
            klips
            button
            list={KLIPS_AGENCY_PREMIER}
            titles={KLIPS_AGENCY_COMPARISON}
            monthly={monthly}
          />
        </Div>
      </MobileWrapper>
    </motion.div>
  )
}

KlipsAgencyPricing.propTypes = {
  monthly: PropTypes.bool,
  handleMonthlyClick: PropTypes.func,
  handleYearlyClick: PropTypes.func,
}

export const KlipsBusinessPricing = ({
  monthly,
  handleMonthlyClick,
  handleYearlyClick,
}) => {
  const { shadow } = useTheme()

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <Div className="pricing-main" hideOnMd>
        <Grid
          boxShadow={"0px 4px 32px rgb(0 0 0 / 5%)"}
          columns="repeat(4, 1fr)"
          background="white"
          columnsMd="1fr"
          borderRadius="1rem 1rem 0 0"
          position="sticky"
          top="6rem"
          zIndex={2}
        >
          <PricingHeading
            klips
            info={
              <Selector
                klips
                monthly={monthly}
                handleMonthlyClick={handleMonthlyClick}
                handleYearlyClick={handleYearlyClick}
              />
            }
          />
          <PricingHeading {...KLIPS_BUSINESS_GROW_HEADER} monthly={monthly} />
          <PricingHeading {...KLIPS_BUSINESS_TEAM_HEADER} monthly={monthly} />
          <PricingHeading
            {...KLIPS_BUSINESS_TEAMPLUS_HEADER}
            monthly={monthly}
          />
        </Grid>
        <Grid
          boxShadow={shadow.soft}
          columns="repeat(4, 1fr)"
          background="white"
          columnsMd="1fr"
          borderRadius="0 0 1rem 1rem"
          overflow="hidden"
          margin="0 0 2rem"
        >
          <PricingColumn klips info list={KLIPS_BUSINESS_COMPARISON} />
          <PricingColumn
            klips
            button
            list={KLIPS_BUSINESS_GROW}
            monthly={monthly}
          />
          <PricingColumn
            klips
            button
            list={KLIPS_BUSINESS_TEAM}
            monthly={monthly}
          />
          <PricingColumn
            klips
            button
            list={KLIPS_BUSINESS_TEAMPLUS}
            monthly={monthly}
          />
        </Grid>
      </Div>
      <MobileWrapper className="pricing-mobile">
        <Selector
          klips
          monthly={monthly}
          handleMonthlyClick={handleMonthlyClick}
          handleYearlyClick={handleYearlyClick}
          style={{ margin: "0 auto 2rem", zIndex: 1, position: "relative" }}
        />
        <Div
          borderRadius="1rem"
          overflow="hidden"
          boxShadow={shadow.soft}
          margin="0 0 2rem"
          background="white"
        >
          <PricingHeading {...KLIPS_BUSINESS_GROW_HEADER} monthly={monthly} />
          <PricingColumn
            klips
            button
            list={KLIPS_BUSINESS_GROW}
            titles={KLIPS_BUSINESS_COMPARISON}
            monthly={monthly}
          />
        </Div>
        <Div
          borderRadius="1rem"
          overflow="hidden"
          boxShadow={shadow.soft}
          margin="0 0 2rem"
          background="white"
        >
          <PricingHeading {...KLIPS_BUSINESS_TEAM_HEADER} monthly={monthly} />
          <PricingColumn
            klips
            button
            list={KLIPS_BUSINESS_TEAM}
            titles={KLIPS_BUSINESS_COMPARISON}
            monthly={monthly}
          />
        </Div>
        <Div
          borderRadius="1rem"
          overflow="hidden"
          boxShadow={shadow.soft}
          margin="0 0 2rem"
          background="white"
        >
          <PricingHeading
            {...KLIPS_BUSINESS_TEAMPLUS_HEADER}
            monthly={monthly}
          />
          <PricingColumn
            klips
            button
            list={KLIPS_BUSINESS_TEAMPLUS}
            titles={KLIPS_BUSINESS_COMPARISON}
            monthly={monthly}
          />
        </Div>
      </MobileWrapper>
    </motion.div>
  )
}

KlipsBusinessPricing.propTypes = {
  monthly: PropTypes.bool,
  handleMonthlyClick: PropTypes.func,
  handleYearlyClick: PropTypes.func,
}
