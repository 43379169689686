import React from "react"
import Grid from "@components/elements/grid"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import Div from "@components/elements/div"
import { PricingHeading } from "./pricing-header"
import { Selector } from "./selector"
import { PricingColumn } from "./pricing-column"
import styled, { useTheme } from "styled-components"
import Anchor from "@components/elements/anchor"
import { graphql, useStaticQuery } from "gatsby"
import { processImages } from "@utils/process-images"
import Image from "@components/image"
import media from "../../../styles/media"
import PropTypes from "prop-types"
import {
  PM_PRICING_COMPARISON,
  PM_PRICING_ENTRIES_CUSTOM,
  PM_PRICING_ENTRIES_FREE,
  PM_PRICING_ENTRIES_STANDARD,
  PM_PRICING_HEADER_CUSTOM,
  PM_PRICING_HEADER_FREE,
  PM_PRICING_HEADER_STANDARD,
} from "./powermetrics/pricing-constants"
import { PowerMetricsAddOns } from "./powermetrics/add-ons"
import { PowerMetricsServices } from "./powermetrics/services"
import { PowerMetricsPricingFAQ } from "./powermetrics/faq"

const MobileWrapper = styled(Div)`
  ${media.md_up`
    display: none;
  `}
`

export const PowerMetricsTable = ({ handleClick, monthly, handleMonthlyClick, handleYearlyClick, microsite }) => {
  const { shadow } = useTheme()
  const { data } = useStaticQuery(graphql`
    query PowerMetricsTable {
      data: allDirectusFiles(
        filter: {
          directus_id: {
            in: [
              "065489f1-e960-4e4a-be8c-e1a6f22f34cb"
              "96d3ad6e-3991-41ca-a130-13fccc6199cc"
              "c68df9a6-e043-43cc-b8ef-5cd937a3cbd8"
              "1192b40b-c5b0-4768-b1af-a5d5c1555cc0"
            ]
          }
        }
      ) {
        edges {
          node {
            directus_id
            cdn
            id
            placeholder
            title
          }
        }
      }
    }
  `)
  const images = processImages(data.edges)
  const servicesPage = microsite ? "/company/services" : "/services"

  return (
    <Div container>
      <Div className="pricing-main" hideOnMd borderRadius="1rem">
        <Grid
          boxShadow={"0px 4px 32px rgb(0 0 0 / 5%)"}
          columns="repeat(4, 1fr)"
          background="white"
          columnsMd="1fr"
          borderRadius="1rem 1rem 0 0"
          position="sticky"
          top="6rem"
          zIndex={2}
        >
          <PricingHeading
            info={
              <Selector
                monthly={monthly}
                handleMonthlyClick={handleMonthlyClick}
                handleYearlyClick={handleYearlyClick}
              />
            }
          />
          <PricingHeading
            {...PM_PRICING_HEADER_FREE}
            monthly={monthly}
          />
          <PricingHeading
            {...PM_PRICING_HEADER_STANDARD}
            monthly={monthly}
          />
          <PricingHeading
            {...PM_PRICING_HEADER_CUSTOM}
            monthly={monthly}
            isAvailableMonthly={false}
            handleYearlyClick={handleYearlyClick}

          />
        </Grid>
        <Grid
          boxShadow={shadow.soft}
          columns="repeat(4, 1fr)"
          background="white"
          columnsMd="1fr"
          borderRadius="0 0 1rem 1rem"
          overflow="hidden"
          margin="0 0 2rem"
        >
          <PricingColumn info list={PM_PRICING_COMPARISON} />
          <PricingColumn
            button
            list={PM_PRICING_ENTRIES_FREE}
            monthly={monthly}
          />
          <PricingColumn
            button
            list={PM_PRICING_ENTRIES_STANDARD}
            monthly={monthly}
          />
          <PricingColumn
            list={PM_PRICING_ENTRIES_CUSTOM}
            monthly={monthly}
            isAvailableMonthly={false}
            handleYearlyClick={handleYearlyClick}
            onClick={handleClick}
          />
        </Grid>
      </Div>

      <MobileWrapper
        className="pricing-mobile"
        style={{ zIndex: 1, position: "relative" }}
      >
        <Selector
          monthly={monthly}
          handleMonthlyClick={handleMonthlyClick}
          handleYearlyClick={handleYearlyClick}
          style={{ margin: "0 auto 2rem", zIndex: 1, position: "relative" }}
        />
        <Div
          borderRadius="1rem"
          overflow="hidden"
          boxShadow={shadow.soft}
          margin="0 0 2rem"
          background="white"
        >
          <PricingHeading
            {...PM_PRICING_HEADER_FREE}
            monthly={monthly}
          />
          <PricingColumn
            button
            list={PM_PRICING_ENTRIES_FREE}
            titles={PM_PRICING_COMPARISON}
            monthly={monthly}
          />
        </Div>
        <Div
          borderRadius="1rem"
          overflow="hidden"
          boxShadow={shadow.soft}
          margin="0 0 2rem"
          background="white"
        >
          <PricingHeading
            {...PM_PRICING_HEADER_STANDARD}
            monthly={monthly}
          />
          <PricingColumn
            button
            list={PM_PRICING_ENTRIES_STANDARD}
            titles={PM_PRICING_COMPARISON}
            monthly={monthly}
          />
        </Div>
        <Div
          borderRadius="1rem"
          overflow="hidden"
          boxShadow={shadow.soft}
          margin="0 0 2rem"
          background="white"
        >
          <PricingHeading
            {...PM_PRICING_HEADER_CUSTOM}
            monthly={monthly}
            isAvailableMonthly={false}
          />
          <PricingColumn
            list={PM_PRICING_ENTRIES_CUSTOM}
            titles={PM_PRICING_COMPARISON}
            monthly={monthly}
            isAvailableMonthly={false}
            onClick={handleClick}
          />
        </Div>
      </MobileWrapper>

      <Div textContainer>
        <Paragraph fontSize="1rem" margin="0 0 4rem">
          Prices shown in USD/mo, based on an annual subscription and billed
          annually. Enterprise plan is only available as an annual
          subscription.&nbsp;
          <Anchor link={servicesPage}>Learn more</Anchor> about Onboarding,
          Training, Professional Services and Proof of Concept options.
        </Paragraph>
      </Div>

      <PowerMetricsAddOns handleClick={handleClick} />

      <PowerMetricsServices servicesPage={servicesPage} />

      <Div>
        <Paragraph
          fontWeight={600}
          fontSize="1.5rem"
          lineHeight={1.5}
          maxWidth="800px"
          margin="0 auto 2rem"
          center
          style={{ position: "relative", zIndex: 12 }}
        >
          Recognized as <b>most recommended</b> (Software Advice) and a&nbsp;
          <b>category leader</b> (G2 Crowd) in Analytics and Business
          Intelligence.
        </Paragraph>

        <Image
          style={{
            margin: "0 auto",
            padding: "0 2rem",
            maxWidth: "600px",
          }}
          file={images["1192b40b-c5b0-4768-b1af-a5d5c1555cc0"]}
        />
      </Div>

      <Div margin="12rem auto" textContainer>
        <Heading as="h2" fontSize="3rem" center margin="0 0 2rem">
          Frequently Asked Questions
        </Heading>
        <PowerMetricsPricingFAQ
          handleClick={handleClick}
          servicesPage={servicesPage}
        />
      </Div>
    </Div>
  )
}

PowerMetricsTable.propTypes = {
  handleClick: PropTypes.func,
  monthly: PropTypes.bool,
  handleMonthlyClick: PropTypes.func,
  handleYearlyClick: PropTypes.func,
  microsite: PropTypes.bool,
}
